import { styled } from './styled';
import { Text } from './text';

export const List = styled('ul', {
	listStyle: 'list',
	padding: '0 0 0 1.4rem',
	margin: 0,
	'> *': {
		marginTop: '$3',
		marginBottom: '$3',

		lg: {
			marginTop: '$4',
			marginBottom: '$4',
		},
	},
});

export const ListItem = styled(Text, {});
