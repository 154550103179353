import React, { Children, useRef, useState } from 'react';
import { MDXProvider } from '@mdx-js/react';

import { Heading, Text } from './text';
import { Anchor } from './interactive';
import { Clipped } from './clipped';
import { List, ListItem } from './list';
import { Pre } from './pre';
import { HorizontalRuler } from './hr';
import { StyledArticleFlow, StyledBlock } from './layout';

import { StyledTable, StyledTh, StyledTd } from './table';

export const MdxWrapper = ({ children, ...props }) => {
	const updatedChildren = Children.toArray(children).map((child, i) => {
		const { children, className } = child.props;
		if (className === 'gatsby-resp-image-wrapper') {
			return (
				<StyledBlock as="figure" key={`notext${i}`}>
					{children}
				</StyledBlock>
			);
		}

		return child;
	});

	return <StyledArticleFlow {...props}>{updatedChildren}</StyledArticleFlow>;
};

const components = {
	wrapper: MdxWrapper,
	clipped: Clipped,
	h1: props => (
		<>
			<Heading as="h1" size="1" {...props} />
		</>
	),
	h2: props => (
		<>
			<Heading as="h2" size="2" {...props} />
		</>
	),
	h3: props => (
		<>
			<Heading as="h3" size="3" {...props} />
		</>
	),
	h4: props => (
		<>
			<Heading as="h4" size="4" {...props} />
		</>
	),
	h5: props => (
		<>
			<div />
			<Heading as="h5" size="5" {...props} />
		</>
	),
	h6: props => (
		<>
			<Text as="p" {...props} />
		</>
	),
	strong: props => <Text as="strong" weight="700" {...props} />,
	em: props => <Text as="em" fontStyle="italic" {...props} />,
	p: props => <Text as="p" {...props} />,
	ol: props => <List as="ol" {...props} />,
	ul: props => <List as="ul" {...props} />,
	li: props => <ListItem as="li" {...props} />,
	a: props => <Anchor {...props} />,
	hr: props => {
		return (
			<>
				<HorizontalRuler role="presentation" {...props} />
			</>
		);
	},
	img: ({ src, alt }) => {
		return (
			<StyledBlock bleed={true}>
				<img src={src} alt={alt} />
			</StyledBlock>
		);
	},
	table: StyledTable,
	th: StyledTh,
	td: StyledTd,
	pre: preProps => (
		<StyledBlock>
			<Pre {...preProps} />
		</StyledBlock>
	),
};

export default ({ children }) => (
	<MDXProvider components={components}>{children}</MDXProvider>
);
