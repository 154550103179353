import { styled } from './styled';

/**
 * Exports `Clipped` component to provide text for screen readers that is visually hidden.
 */
export const Clipped = styled('div', {
	clip: 'rect(0 0 0 0',
	clipPath: 'inset(50%)',
	height: '1px',
	overflow: 'hidden',
	position: 'absolute',
	whiteSpace: 'nowrap',
	width: '1px',
});
