import { styled } from './styled';

/**
 * Heading component
 */
export const Heading = styled('span', {
	fontFamily: '$sans',
	fontWeight: '$500',

	variants: {
		size: {
			1: {
				fontSize: '$10',
				lineHeight: '$1',
				md: {
					fontSize: '$11',
				},
				lg: {
					fontSize: '$12',
				},
			},
			2: {
				fontSize: '$8',
				lineHeight: '$1',
				md: {
					fontSize: '$9',
				},
			},
			3: {
				lineHeight: '$2',
				fontSize: '$6',
				md: {
					fontSize: '$7',
				},
			},
			4: {
				lineHeight: '$3',
				fontSize: '$5',
				md: {
					fontSize: '$6',
				},
				lg: {
					fontSize: '$6',
				},
			},
			5: {
				lineHeight: '$3',
				fontSize: '$4s',
				md: {
					fontSize: '$5',
				},
				lg: {
					fontSize: '$5',
				},
			},
			6: {
				lineHeight: '$3',
				fontSize: '$4',
				fontWeight: '$400',
				lg: {
					fontSize: '$4',
				},
			},
		},
		weight: {
			400: {
				fontWeight: '$400',
			},
			500: {
				fontWeight: '$500',
			},
			700: {
				fontWeight: '$700',
			},
		},
	},
});

/**
 * Text component
 */
export const Text = styled('span', {
	fontFamily: '$sans',
	lineHeight: '$6',
	fontWeight: '$400',
	fontSize: '$4s',
	whiteSpace: 'pre-line',
	md: {
		fontSize: '$5',
	},
	lg: {
		fontSize: '$5s',
	},

	variants: {
		size: {
			large: {
				fontSize: '$6',
				lineHeight: '$3',
				fontWeight: '$400',
				md: {
					fontSize: '$6',
				},
				lg: {
					fontSize: '$7',
				},
			},
		},
		fontStyle: {
			italic: {
				fontStyle: 'italic',
			},
			regular: {
				fontStyle: 'normal',
			},
		},
		weight: {
			400: {
				fontWeight: '$400',
			},
			500: {
				fontWeight: '$500',
			},
			700: {
				fontWeight: '$700',
			},
		},
		color: {
			mono0: {
				color: '$mono0',
			},
			mono10: {
				color: '$mono10',
			},
			mono20: {
				color: '$mono20',
			},
			mono30: {
				color: '$mono30',
			},
			mono40: {
				color: '$mono40',
			},
			mono50: {
				color: '$mono50',
			},
			mono60: {
				color: '$mono60',
			},
			mono70: {
				color: '$mono70',
			},
			mono80: {
				color: '$mono80',
			},
			mono90: {
				color: '$mono90',
			},
			mono100: {
				color: '$mono100',
			},
		},
	},
});
