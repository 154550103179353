import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Mdx from '../ui/mdx';
import {
	StyledMain,
	StyledArticle,
	StyledArticleHeader,
	StyledContainer,
} from '../ui/layout';
import { Heading, Text } from '../ui/text';

export default props => {
	const mdx = props.data.allMdx.nodes[0];
	return (
		<>
			<StyledMain>
				<StyledArticle>
					<StyledContainer>
						<StyledArticleHeader>
							<Heading size={1}>{mdx.frontmatter.title}</Heading>
							{mdx.frontmatter.description && (
								<Text size="large" color="mono50">
									{mdx.frontmatter.description}
								</Text>
							)}
						</StyledArticleHeader>
						<Mdx>
							<MDXRenderer children={mdx.body} />
						</Mdx>
					</StyledContainer>
				</StyledArticle>
			</StyledMain>
		</>
	);
};

export const pageQuery = graphql`
	query {
		allMdx(
			filter: {
				fields: { sourceType: { eq: "site" }, path: { eq: "home" } }
			}
		) {
			nodes {
				frontmatter {
					title
					description
				}
				body
				tableOfContents
			}
		}
	}
`;
