import { styled } from './styled';

export const StyledTable = styled('table', {
	width: '100%',
	borderSpacing: '0',
});

export const StyledTh = styled('th', {
	padding: '0 0 $3 0',
	textAlign: 'left',
	borderBottom: '1px solid',
	borderColor: '$mono30',
	fontFamily: '$sans',
	lineHeight: '$6',
	fontWeight: '$500',
	fontSize: '$4s',
	md: {
		fontSize: '$5',
	},
	lg: {
		fontSize: '$5',
	},
});

export const StyledTd = styled('td', {
	padding: '$3 0 $0 0',
	textAlign: 'left',
	fontFamily: '$sans',
	lineHeight: '$6',
	fontWeight: '$400',
	fontSize: '$4s',
	md: {
		fontSize: '$5',
	},
	lg: {
		fontSize: '$5',
	},
});
